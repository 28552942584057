
  import { defineComponent, onMounted, onUnmounted } from "vue";
  import { useStore } from "vuex";
  import { Actions } from "../../../store/enums/StoreEnums";
  import { getIllustrationsPath } from "../../../core/helpers/assets";
  import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
  import { ref } from "vue";
  import { reactive } from "vue";
  import { DateTimeUtils } from "@/latipay/utils/DateTimeUtils";

  import { reinitializeComponents } from "@/core/plugins/keenthemes";
  import { EnumsConstant } from "@/store/enums/constants";
  import { getWalletStatementsRequest } from "@/latipay/apis/services/WalletStatementsService";


  export default defineComponent({
    name: "walletAdjustment",
    components: {},
    setup() {
      const store = useStore();
      const merchantId = ref<string | null>(null);
      const walletId = ref<string | null>(null);
      const type = ref<string | null>(null);
      const currency = ref<string | null>(null);

      const sort = ref<string | null>(null);
      const pageNumber = ref(1);
      const pageSize = ref(20);

      const loading = ref(false);
      const countries = EnumsConstant.CountryCode;
      const currencies = EnumsConstant.CurrencyCode;


      const formInline = reactive({
        merchantId: "",
        walletId: "",
        currency: "",
      });

      const {
        walletStatementsRef,
        totalRef,
        pageNumberRef,
        pageSizeRef,
        getWalletStatements
      } = getWalletStatementsRequest();


      const getData = async () => {
        loading.value = true;

        // await updateQueries();
        await getWalletStatements(
                formInline.merchantId,
                formInline.walletId,
                'Adjust',
                formInline.currency,

                sort.value,
                pageNumber.value,
                pageSize.value
        );
        reinitializeComponents();

        loading.value = false;

        console.log(walletStatementsRef);
      };




      onMounted(async () => {
        // setCurrentPageTitle("Users");
        await getData();
      });

      const updatePageSize = async (val: number) => {
        console.log(`${val} items per page`);
        pageSize.value = val;
        await getData();
      };
      const updatePageNumber = async (val: number) => {
        console.log(`current page: ${val}`);
        pageNumber.value = val;
        await getData();
      };


      return {
        getIllustrationsPath,
        formInline,
        countries,

        getData,
        loading,
        updatePageSize,
        updatePageNumber,
        walletStatementsRef,
        totalRef,
        pageNumberRef,
        pageSizeRef,
        merchantId,
        walletId,
        type,
        currency,
        DateTimeUtils,
      };
    },
    methods: {
      async resetFormFields() {

        (this.$refs["searchForm"] as any).resetFields();

        await this.getData();

      },
      async onSubmitSearchForm() {
        await this.getData();
      }

    }
  });
